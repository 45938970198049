import axios from "axios";

const API_BASE_URL = "https://zetapi.tech-enabler.online/api";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers['Accept'] = `application/json`;
    config.headers["Authorization"] = 'Bearer ' + localStorage.getItem("token");
    return config;
  },
)

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    window.location.href = '/';
    localStorage.removeItem("token");
  }
  return Promise.reject(error);
});

const api = {

  // get url use 
  get(url) {
    return axiosInstance.get(url)
  },
  // post url use 
  post(url, data) {
    return axiosInstance.post(url, data)
  },
  // delete url use 
  delete(url) {
    return axiosInstance.delete(url)

  }
};
export default api;