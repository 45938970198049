import React from 'react'
import { useEffect, useState } from 'react'
import api from '../services/ApiUrl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ValidationPage() {
    const navigate = useNavigate();
    const [code, setCode] = useState('');




    useEffect(() => {
        const currentUrl = window.location.href;

        const urlParts = currentUrl.split('?');
        if (urlParts.length === 2) {
            const queryParams = urlParts[1].split('&');
            for (const param of queryParams) {
                const numberMatch = param.match(/\d+/);
                if (numberMatch) {
                    const activationCode = numberMatch[0];
                    console.log(activationCode, 'activationCode');
                    setCode(activationCode);
                    const payload = {
                        activation_code: activationCode
                    };
                    console.log(payload)
                    api.post('/verify_activation_code/', payload)
                        .then((response) => {
                            console.log('API Response:', response);
                            if (response.data[0].success === true) {
                                toast.success(response.data[0].messages[0]);
                                navigate('/');
                            } else if (response.data[0].success === false) {
                                toast.error(response.data[0].messages[0]);
                                navigate('/newaccount');
                            }
                        })
                        .catch((error) => {
                            console.error('API Error:', error);
                        });

                    break;
                }
            }
        }
    }, []);


    return (
        <div className='login-page overflow-y-auto'>
            <div className='login-box'>
                <div className='login-logo'>
                    <h4>Checking the account validation</h4>
                    <p>Please wait...</p>
                </div>
            </div>
        </div>
    )
}

export default ValidationPage
