import React from 'react';
import '../../sass/sidebarCopy.scss'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import api from '../services/ApiUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useChat } from '../handleId'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import ClipLoader from 'react-spinners/ClipLoader';
import { fetchChats, deleteChat, searchChat } from '../../redux/slices/chatsSlice';
import { addEmptyConversation } from '../../redux/slices/userSlice';
// import { usePersonaValue } from '../prosonaValue';
// import { usePersonaValue } from '../prosonaValue';
import { setProsonaValue, setPersona } from '../../redux/slices/personaSlice';


function Sidebar({ handleOpenModal, toggleSidebarClass, handleOpenTitle, handleSetting }) {

    const { setChatId, chatId, setGetModel, setGetTitle, setFirstCharacters, firstCharacters } = useChat()
    const [isDivVisible, setIsDivVisible] = useState(false);
    // const { prosnaValue, setProsonaValue } = usePersonaValue();

    //sidebar
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [sidebarW767, setSidebarW767] = useState(true);

    // apis
    const [search, setSearch] = useState('')
    const [error, setError] = useState([]);
    const [showError, setShowError] = useState(false)
    const location = useLocation();
    const [activeTitle, setActiveTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('');



    // slice states
    const dispatch = useDispatch();
    const chats = useSelector((state) => state.userChats?.chats || []);
    const selected = useSelector((state) => state);

    const handleClick = () => {
        setIsDivVisible(!isDivVisible);
    };

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleClose = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleW767 = () => {
        setSidebarW767(!sidebarW767);
        toggleSidebarClass(sidebarW767 ? 'sidebar-w-767' : '');
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (windowWidth > 767) {
            setSidebarW767(false);
        }
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);

    const shouldRemoveSidebar = windowWidth <= 767;

    const navigate = useNavigate();

    const pathnameParts = location.pathname.split('/');
    const chatNumber = parseInt(pathnameParts[pathnameParts.length - 1]);


    useEffect(() => {
        api.get('/users/me')
            .then((res) => {
                
                if (res.data[0].user.first_name == undefined){
                    let email = res.data[0].user.email;
                    let shortenedEmail = email.length > 16 ? email.substring(0, 16) + "..." : email;
                    setFirstName(shortenedEmail);
                }else {
                    setFirstName(res.data[0].user.first_name);
                    setLastName(res.data[0].user.last_name);
                }
                if (res.data[0].user.first_name == undefined){
                    const firstChar = res.data[0].user.email.charAt(0)+res.data[0].user.email.charAt(1);
                    setFirstCharacters(firstChar.toUpperCase());
                }else {
                    const firstChar = res.data[0].user.first_name.charAt(0);
                    const LastCharacter = res.data[0].user.last_name.charAt(0);
                    setFirstCharacters(`${firstChar}${LastCharacter}`);
                }
            }).catch((err) => {
                console.log(err, 'user error')
            })
    }, [])

    const getChats = () => {
        dispatch(fetchChats())
            .then((res) => {
                if (res.payload.success === true) {

                    if (titleFromUrl && idFromUrl) {
                        setActiveTitle(titleFromUrl);
                    }
                    setShowError(false)
                }


            }).finally(() => {
                setIsLoading(false)
            })
    }

    const handleSearch = (e) => {
        const token = localStorage.getItem('token');
        const inputSearch = e ? e.target.value : search;
        setSearch(inputSearch);

        dispatch(searchChat({ inputSearch }))
            .then((res) => {
                console.log(res, 'user search res')
                if (res.payload.success === true) {
                    setShowError(false)
                }
                const searchParams = new URLSearchParams({ title: inputSearch });
                const newUrl = `${location.pathname}?${searchParams.toString()}`;
                window.history.pushState({ path: newUrl }, '', newUrl);
                if (res.payload.success === false) {
                    const chats = res.payload.messages;
                    setError(chats);
                    console.log(chats, 'search error')
                    setShowError(true);

                }
            })
    }

    const handleDelteChat = (id, e) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteChat({ id }))
                    .then((res) => {
                        if (res.payload.status === 200) {
                            dispatch(addEmptyConversation());
                            getChats();
                            setGetModel('');
                            setGetTitle('');
                            toast.success(res.payload.data[0].messages[0]);
                            navigate('/content')
                        }
                    }).catch((error) => {
                        console.error('Error deleting chat', error);
                    }).finally(() => {
                        setIsLoading(false)
                    })
            }
        });
    }

    const getChatConversations = (id, title, model, context, e) => {
        e.preventDefault();
        setChatId(id);
        setActiveTitle(title);
        setGetModel(model)
        setGetTitle(title)
        dispatch(setProsonaValue(context));
        navigate(`/c/${id}`);
    };

    useEffect(() => {

        const idFromUrl = window.location.search;
        const parts = idFromUrl.split('/');

        if (parts[4]) {
            setChatId(parts[4]);
            console.log(chats)
            chats.forEach((chat) => {
                console.log(chat, 'chat')
            });
        }

    }, [chats]);



    const handelLogOut = () => {
        api.post('/logout', null, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'Accept': 'application/json',
                'content-type': 'application/json'
            }
        }).then((res) => {
            console.log(res, 'logout res');
            navigate('/');
            if (res.status === 200) {
                toast.success(res.data.data.message);
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                setChatId(null);
                setGetModel('');
                dispatch(setProsonaValue(''));

                setFirstCharacters('');

            }

        }).catch((error) => {
            console.log(error, 'logout error')
        })
    }

    const GoChangePassword = () => {
        navigate('/changepassword')
        setIsDivVisible(!isDivVisible)
    }

    const queryParams = new URLSearchParams(location.search);
    const titleFromUrl = queryParams.get('title');
    const idFromUrl = queryParams.get('id');

    useEffect(() => {
        if (titleFromUrl && idFromUrl) {
            setActiveTitle(titleFromUrl);
            setChatId(idFromUrl);
        }

        getChats();
        const newUrl = location.pathname;
        window.history.pushState({ path: newUrl }, '', newUrl);
        if (search) {
            handleSearch();
        }
    }, [titleFromUrl, idFromUrl, location.search, search]);


    return (
        <React.Fragment>
            <div className='sidebar-btn-w-767' onClick={handleW767}>
                <div className='sidebar-btn-w-767-div'>
                    <div className="icon"></div>
                </div>
            </div>
            <div className={`tool open-sidebar ${isSidebarVisible ? 'hidden' : ''}`} onClick={handleClose} data-toggle="tooltip" data-placement="top" title="Open sidebar">
                <div className='side-open-div'>
                    <div className="icon"></div>
                </div>
            </div>
            <div className={`${isSidebarVisible && !shouldRemoveSidebar ? 'sidebar' : 'hidden'} ${sidebarW767 ? 'sidebar-w-767' : 'sidebar-w-0'}`}>
                <div className='sidebar-content'>
                    <div className='sidebar-top'>
                        <div className='part-one'>
                            <button className='chat' onClick={handleOpenTitle}>
                                <i className='plus-icon'></i>
                                <div className='new-chat'><p>New chat</p></div>
                            </button>
                            <div className='side-icon' onClick={toggleSidebar}>
                                <div className='sidebar-icon'></div>
                            </div>
                        </div>
                        <div className='part-two'>
                            <i className='search-icon'></i>
                            <input placeholder='Search...' value={search} onChange={handleSearch} />
                        </div>
                    </div>
                    <div className='sidebar-menu'>
                        <div className='chat-section'>
                            <div className='heading'>
                                <span>Today</span>
                            </div>
                            <ul>
                                {showError ? (
                                    <div>{error}</div>
                                ) :

                                    (
                                        <div>
                                            {isLoading ? (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <ClipLoader loading={isLoading} size={20} color="#fff" />

                                                </div>
                                            ) : (
                                                <>
                                                    {chats.map((chat) => {
                                                        const isActive = chatId === chat.id;
                                                        const chatLength = chats.length > 1;
                                                        const isActive2 = chatNumber === chat.id;

                                                        const activeChat = chatNumber === chat.id ? chat : null;
                                                        if (activeChat) {
                                                            setGetModel(activeChat.model);
                                                            setGetTitle(activeChat.title);
                                                            // dispatch(setPersona(activeChat.context));
                                                            // dispatch(setProsonaValue(activeChat.context));
                                                        }

                                                        return (
                                                            <li className={`chat ${isActive ? 'active' : ''} ${isActive2 ? 'active' : ''}`} key={chat.id} onClick={(e) => {
                                                                getChatConversations(chat.id, chat.title, chat.model, chat.context, e);
                                                            }}>
                                                                <div className="chat-content">
                                                                    <div className='icon'></div>
                                                                    <div className='text'>
                                                                        <p>{chat.title.length > 32 ? `${chat.title.slice(0, 32)}...` : `${chat.title}`}</p>
                                                                    </div>
                                                                    <div className='actions'>
                                                                        {chatLength && (
                                                                            <div className={`${isActive || isActive2 ? 'delete' : ''}`} onClick={() => handleDelteChat(chat.id)}></div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    )}

                            </ul>
                        </div>

                    </div>
                    <div className='sidebar-footer'>
                        {isDivVisible ? (
                            <div className='profile-options'>
                                <ul className="list-group option-group">
                                    <li className="list-group-item option-item" onClick={GoChangePassword}>
                                        <div className='option'>
                                            <div className='help'></div>
                                            <div className='text'>Change Password</div>
                                        </div>
                                    </li>
                                    <li className="list-group-item option-item" onClick={() => { handleSetting(); handleClick() }}>
                                        <div className='option'>
                                            <div className='setting'></div>
                                            <div className='text'>Setting</div>
                                        </div>
                                    </li>
                                    <li className="list-group-item option-item">
                                        <div className='option'>
                                            <div className='setting'></div>
                                            <div className='text'>Change Image</div>
                                        </div>
                                    </li>
                                    <li className="list-group-item option-item logout-border" onClick={handelLogOut}>
                                        <div className='option'>
                                            <div className='logout'></div>
                                            <div className='text'>Log out</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        ) : (
                            <div className='templete' onClick={handleOpenModal}>
                                <div className="templete-div">
                                    <div className='icon'></div>
                                    <div className='text'>
                                        <p>Templates</p>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                        <div className="profile-section" onClick={handleClick}>
                            <div className='profile-div'>
                                <div className='profile-user'>
                                    <div className='user-icon'><p>{firstCharacters}</p></div>
                                    <div className='text'>
                                        <p>{first_name} {last_name}</p>
                                    </div>
                                </div>
                                <div className='option-icon'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default Sidebar