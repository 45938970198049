import React, { useEffect } from 'react'
// import '../sass/Login.scss'
import '../../sass/Login.scss'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useChat } from '../handleId';
import api from '../services/ApiUrl';

function Login() {
    const { chatId } = useChat()

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState([]);
    const [showError, setShowError] = useState(false)
    const [closeError, setCloseError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseError = () => {
        setCloseError(prevCloseError => !prevCloseError);
    }
    const customLoaderStyles = {
        width: '20px',
        height: '20px',
        marginTop: '9px',
        borderRadius: '100%',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'rgb(5, 5, 5) rgb(5, 5, 5) transparent',
        borderImage: 'initial',
        display: 'inline-block',
        animation: '0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip',
    };
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/')
        } else {
            navigate('/content')
        }
    }, [])

    const handlePost = (e) => {
        e.preventDefault();
        setIsLoading(true)
        let formData = {
            email: email,
            password: password
        }
        api.post('/login', formData)
            .then((res) => {
                console.log(res)
                // localStorage.setItem('token', res.data.data.access_token);
                // if (res.data[0].success === false) {
                //     toast.error(res.data[0].messages)
                // } else {
                //     toast.success(res.data.data.message);
                // }

                if (res.data.success === true) {
                    console.log(res)
                    localStorage.setItem('token', res.data.data.access_token);
                    toast.success(res.data.data.message);
                    if (res.data.data.role === "user") {
                        navigate('/content');
                        localStorage.setItem('user', true)
                    } else if (res.data.data.role === "admin") {
                        navigate('/admin');
                    }
                } else if (res.data[0].success === false) {
                    console.log('Error Data:', res);
                    const errorMessage = res.data[0].messages[0];
                    setError([errorMessage]);
                }

            })
            .catch((err) => {
                // console.log(err)
                if (err.code === "ERR_NETWORK") {
                    setError(['NetWork Error']);
                }
                // else {
                //     setError([err.response.data.detail])
                // }
                setTimeout(() => {
                    setError([]);
                }, 5000)
            })
            .finally(() => {
                setIsLoading(false)
                setTimeout(() => {
                    setError([]);
                }, 5000)
            })
    };
    return (
        <div className='login-page'>
            <div className='login-box'>
                <div className='login-logo'>
                    <h4 className='heading'>Welcome back</h4>
                </div>
                <div className='card mt-20'>
                    {error.length > 0 && !closeError && (
                        <div className='error'>
                            {error.map((e, i) => (
                                <div className='error-div' key={i}>
                                    <div className='msg'>
                                        <p>{e}</p>
                                    </div>
                                    <div className='close-icon' onClick={handleCloseError}></div>
                                </div>
                            ))}
                        </div>
                    )}


                    <form onSubmit={handlePost}>
                        <div className='card-body'>
                            <input type='email' placeholder='Email address' name='email' value={email} className='email-input' onChange={(e) => setEmail(e.target.value)} />
                            <input type='password' placeholder='Password' name='password' value={password} className='email-input' onChange={(e) => setPassword(e.target.value)} />
                            <button type='submit' className='btn btn-continue'>
                                {isLoading ? (
                                    <ClipLoader style={customLoaderStyles} loading={isLoading} size={20} color="#050505" />
                                ) : (
                                    'Continue'
                                )}
                            </button>

                            <div className='sign-para'>
                                <p>Don't have an account? <span><Link style={{ color: '#10A37F' }} to='/newaccount'>Sign up</Link></span></p>
                            </div>
                            <div className='sign-para'>
                                <p>If Forget Password?<span><Link style={{ color: '#10A37F' }} to='/forgetpassword'> Click here!</Link></span></p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login